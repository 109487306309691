<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">PRIVACY POLICY</h1>

          <h3>1. Titolare del trattamento</h3>
          <p>
            Saturno Consulting Srl<br>
            Sede legale: Via Cascina 6, 67100 L'Aquila (AQ) – Italia<br>
            P.IVA: 02009750668<br>
            Email di contatto: <a href="mailto:info@saturnoconsulting.com">info@saturnoconsulting.com</a>
          </p>

          <h3>2. Tipologie di dati raccolti</h3>
          <p>Navigando su questo sito web, possono essere raccolti i seguenti dati:</p>
          <ul>
            <li><strong>Dati di navigazione</strong>: informazioni anonime raccolte automaticamente come indirizzo IP, tipo di browser, sistema operativo, orario di accesso e pagine visitate, tramite strumenti come Google Analytics.</li>
            <li><strong>Dati forniti volontariamente</strong>: dati personali (nome, cognome, indirizzo email, eventuali altri dati inseriti nei messaggi) forniti dall’utente tramite <strong>form di contatto</strong>.</li>
            <li><strong>Font esterni</strong>: il sito utilizza <strong>Google Fonts</strong>, che può raccogliere dati tecnici (indirizzo IP, informazioni sul browser) per il corretto caricamento dei caratteri.</li>
          </ul>

          <h3>3. Finalità del trattamento</h3>
          <p>I dati personali raccolti vengono trattati per le seguenti finalità:</p>
          <ul>
            <li>Rispondere a richieste inviate tramite il form di contatto;</li>
            <li>Migliorare l’esperienza utente del sito;</li>
            <li>Analizzare il traffico e l’utilizzo del sito (tramite Google Analytics);</li>
            <li>Ottemperare a obblighi di legge.</li>
          </ul>

          <h3>4. Base giuridica del trattamento</h3>
          <ul>
            <li><strong>Consenso</strong> dell’interessato per i dati inviati tramite i form;</li>
            <li><strong>Legittimo interesse</strong> per l’analisi del traffico anonimo tramite strumenti statistici;</li>
            <li><strong>Obblighi legali</strong> per eventuali richieste dell’autorità giudiziaria.</li>
          </ul>

          <h3>5. Modalità di trattamento e conservazione dei dati</h3>
          <p>I dati sono trattati in modalità informatica, con misure tecniche e organizzative adeguate per garantire la sicurezza.<br>
            I dati personali raccolti tramite i form sono conservati per il tempo necessario a gestire la richiesta e successivamente cancellati, salvo necessità di conservazione per obblighi legali.</p>

          <h3>6. Comunicazione a terzi</h3>
          <p>I dati non vengono diffusi. Possono essere comunicati a soggetti esterni che forniscono servizi funzionali al sito (es. provider hosting, strumenti analitici come Google Analytics), in qualità di responsabili del trattamento.</p>

          <h3>7. Trasferimento dei dati extra-UE</h3>
          <p>I dati possono essere trasferiti al di fuori dell’Unione Europea (es. Stati Uniti) tramite l’utilizzo di servizi come <strong>Google Fonts</strong> e <strong>Google Analytics</strong>. Tali trasferimenti avvengono nel rispetto delle Clausole Contrattuali Standard approvate dalla Commissione Europea o altri strumenti legittimi previsti dal GDPR.</p>

          <h3>8. Diritti dell’interessato</h3>
          <p>L’utente può in qualsiasi momento esercitare i diritti previsti dagli artt. 15-22 del GDPR, tra cui:</p>
          <ul>
            <li>Ottenere conferma dell’esistenza dei dati e l’accesso agli stessi;</li>
            <li>Richiedere la rettifica, cancellazione o limitazione del trattamento;</li>
            <li>Opporsi al trattamento;</li>
            <li>Proporre reclamo all’Autorità Garante per la protezione dei dati personali.</li>
          </ul>

          <h3>9. Cookie</h3>
          <p>Il sito utilizza <strong>cookie tecnici</strong> necessari al funzionamento del sito e <strong>cookie analitici</strong> di terze parti (Google Analytics).</p>

          <h3>10. Modifiche alla presente informativa</h3>
          <p>La presente Privacy Policy può essere soggetta ad aggiornamenti. Si consiglia di consultare periodicamente questa pagina.</p>

          <p><em>Ultimo aggiornamento: 2 aprile 2025</em></p>
          <router-link to="/" class="btn btn-dark btn-lg">Vai alla home</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  mounted() {

  }
}
</script>

<style scoped>

</style>