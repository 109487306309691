import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home'
import Contact from '../views/Contact'
import Digital from "@/views/Digital";
import Retail from "@/views/Retail";
import Industrial from "@/views/Industrial";
//import Products from "@/views/Products";
import Ffriday from "@/views/Ffriday";
import Connettore from "@/views/Connettore";
import DistintaBase from "@/views/DistintaBase";
import CasaSaturno from "@/views/CasaSaturno";
import AmicoPos from "@/views/AmicoPos";
import VCardRenzo from '@/views/VCardRenzo.vue';
import VCardRiccardo from "@/views/VCardRiccardo.vue";
import VCardAntonello from "@/views/VCardAntonello.vue";
import VCardAngelo from "@/views/VCardAngelo.vue";
import NotFound from "@/views/NotFound";
import VCardAnnamaria from "@/views/VCardAnnamaria.vue";
import LaMoraGlamour from "@/views/LaMoraGlamour.vue";
import CaseStudy from "@/views/CaseStudy.vue";
import BikiniLovers from "@/views/BikiniLovers.vue";
import VinoECioccolato from "@/views/VinoECioccolato.vue";
import Clothing from "@/views/Clothing.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta : {
            title : "Saturno Consulting | Software and omnichannel solutions experts",
            description : "Saturno Consulting is software experts, omnichannel company and AI experts for retail commerce."
        }
    },
    {
        path: '/contatti',
        name: 'Contatti',
        component: Contact,
        meta : {
            title : "Contacts | Saturno Consulting",
            description : "Request an advice from one of our departments by following the form below to contact us."
        }
    },
    {
        path: '/casasaturno',
        name: 'Casa Saturno',
        component: CasaSaturno,
        meta : {
            title : "Casa Saturno | Saturno Consulting",
            description : "Casa Saturno is the headquarter of Saturno Consulting, in the historical center of L'Aquila."
        }
    },
    {
        path: '/digital',
        name: 'Digital',
        component: Digital,
        meta : {
            title : "Digital | Saturno Consulting",
            description : "Saturno Consulting e la sua parte digitale per la realizzazione di software in cloud."
        }
    },
    {
        path: '/retail',
        name: 'Retail',
        component: Retail,
        meta : {
            title : "Retail | Saturno Consulting",
            description : "Saturno Consulting e la sua parte digitale per la realizzazione di software in cloud."
        }
    },
    {
        path: '/clothing',
        name: 'Clothing',
        component: Clothing,
        meta : {
            title : "Abbiglimento | Saturno Consulting",
            description : "Saturno Consulting e la sua parte digitale per la realizzazione di software in cloud."
        }
    },
    {
        path: '/industrial',
        name: 'Industrial',
        component: Industrial,
        meta : {
            title : "Industrial | Saturno Consulting",
            description : "Saturno Consulting e la sua divisione industriale nel mondo della gomma."
        }
    },
    {
        path: '/ffriday',
        name: 'FFriday',
        component: Ffriday,
        meta : {
            title : "FFriday | Saturno Consulting",
            description : "Fissa gli obiettivi, controlla i costi, aumenta i profitti. FFriday è il nuovo software di ROI management sviluppato da noi."
        }
    },
    {
        path: '/connettore',
        name: 'Connettore',
        component: Connettore,
        meta : {
            title : "WooConnector | Saturno Consulting",
            description : "Plugin WooCommerce per l'integrazione tra di Cassa in Cloud a WooCommerce."
        }
    },
    {
        path: '/distintabase',
        name: 'DistintaBase',
        component: DistintaBase,
        meta : {
            title : "Distinta base | Saturno Consulting",
            description : "Software per l'integrazione della gestione delle materie prime ed i prodotti composti con Cassa in Cloud."
        }
    },
    {
        path: '/amicopos',
        name: 'AmicoPos',
        component: AmicoPos,
        meta : {
            title : "Amico POS | Saturno Consulting",
            description : "Casa Saturno è l'ufficio di Saturno Consulting, in pieno centro a L'Aquila"
        }
    },
    /*
    {
        path: '/prodotti',
        name: 'Products',
        component: Products,
        meta : {
            title : "Prodotti | Saturno Consulting",
            description : "Casa Saturno è l'ufficio di Saturno Consulting, in pieno centro a L'Aquila"
        }
    },
     */
    {
        path: '/casestudies',
        name: 'Casi studio',
        component: CaseStudy,
        meta : {
            title : "Casi studio | Saturno Consulting",
            description : "Casa Saturno è l'ufficio di Saturno Consulting, in pieno centro a L'Aquila"
        }
    },
    {
        path: '/lamoraglamour',
        name: 'La Mora Glamour',
        component: LaMoraGlamour,
        meta : {
            title : "La Mora Glamour | Saturno Consulting",
            description : ""
        }
    },
    {
        path: '/bikinilovers',
        name: 'Bikini Lovers',
        component: BikiniLovers,
        meta : {
            title : "Bikini Lovers | Saturno Consulting",
            description : ""
        }
    },
    {
        path: '/vinoecioccolato',
        name: 'Vino e Cioccolato',
        component: VinoECioccolato,
        meta : {
            title : "Vino e Cioccolato | Saturno Consulting",
            description : ""
        }
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta : {
            title : "Privacy Policy | Saturno Consulting",
            description : "Saturno Consulting è una software house che realizza software web in cloud per qualsiasi settore."
        }
    },
    {
        path: '/riccardocicerone',
        name: 'Riccardo Cicerone',
        component: VCardRiccardo,
        meta : {
            title : "Biglietto da visita | Saturno Consulting",
            description : "Saturno Consulting è una software house che realizza software web in cloud per qualsiasi settore."
        }
    },
    {
        path: '/renzocappelli',
        name: 'Renzo Cappelli',
        component: VCardRenzo,
        meta : {
            title : "Biglietto da visita | Saturno Consulting",
            description : "Saturno Consulting è una software house che realizza software web in cloud per qualsiasi settore."
        }
    },
    {
        path: '/antonellodulcamara',
        name: 'Antonello Dulcamara',
        component: VCardAntonello,
        meta : {
            title : "Biglietto da visita | Saturno Consulting",
            description : "Saturno Consulting è una software house che realizza software web in cloud per qualsiasi settore."
        }
    },
    {
        path: '/angelopriori',
        name: 'Angelo Priori',
        component: VCardAngelo,
        meta : {
            title : "Biglietto da visita | Saturno Consulting",
            description : "Saturno Consulting è una software house che realizza software web in cloud per qualsiasi settore."
        }
    },
    {
        path: '/annamariacolagrande',
        name: 'Annamaria Colagrande',
        component: VCardAnnamaria,
        meta : {
            title : "Biglietto da visita | Saturno Consulting",
            description : "Saturno Consulting è una software house che realizza software web in cloud per qualsiasi settore."
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        meta : {
            title : "Pagina non trovata | Saturno Consulting",
            description : "Casa Saturno è l'ufficio di Saturno Consulting, in pieno centro a L'Aquila"
        }
    }
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to,from, savedPosition) {
        if (to.name !== "Shop") {
            return window.scrollTo(0,0) ;
        }else if(savedPosition){
            return savedPosition
        }
    }
});

router.beforeEach((to) => {
    document.title = to.meta.title;
    document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description);
});

export default router;